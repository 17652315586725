<script setup lang="ts">

</script>

<template>
	<svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.25" width="48" height="49.0213" rx="13" fill="#7059FF"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M34.5016 15.0663V31.9174C34.5016 33.4691 33.2448 34.7259 31.6931 34.7259H17.3163C15.7646 34.7259 14.5078 33.4691 14.5078 31.9174V15.0663C14.5078 13.5146 15.7646 12.2578 17.3163 12.2578H31.6945C33.2448 12.2578 34.5016 13.5146 34.5016 15.0663Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M29.7707 20.332L22.53 27.7044L19.2388 24.3533" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M34.5024 15.0664H35.7396C37.2913 15.0664 38.5481 16.3232 38.5481 17.8749V29.109C38.5481 30.6607 37.2913 31.9175 35.7396 31.9175H34.5024" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M14.5081 31.9175H13.2709C11.7192 31.9175 10.4624 30.6607 10.4624 29.109V17.8749C10.4624 16.3232 11.7192 15.0664 13.2709 15.0664H14.5081" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<style scoped lang="scss">

</style>