<script setup lang="ts">

</script>

<template>
	<svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" width="48" height="48" rx="13" fill="#7059FF"/>
		<path d="M32.6749 27.3439C33.1079 26.3142 33.3429 25.2119 33.3429 24.0005C33.3429 23.1282 33.2069 22.2924 32.9718 21.5171C32.1676 21.6988 31.3263 21.7957 30.4479 21.7957C28.6491 21.7976 26.8761 21.376 25.2784 20.5664C23.6808 19.7568 22.3054 18.583 21.2681 17.1439C20.1595 19.7736 18.0659 21.8886 15.4162 23.0556C15.3668 23.3584 15.3668 23.6855 15.3668 24.0005C15.3668 25.157 15.5994 26.3022 16.0514 27.3707C16.5034 28.4392 17.1659 29.4101 18.0011 30.2279C19.6879 31.8795 21.9756 32.8074 24.361 32.8074C25.6601 32.8074 26.9096 32.5287 28.0355 32.0321C28.7406 33.3525 29.0623 34.0066 29.0376 34.0066C27.0086 34.6729 25.4374 35 24.361 35C21.3671 35 18.5092 33.8492 16.406 31.7777C15.1256 30.5298 14.1744 28.9957 13.6347 27.3076H11.9893V21.7957H13.3378C13.7463 19.8484 14.6857 18.0457 16.0561 16.5789C17.4265 15.1121 19.1769 14.0359 21.1215 13.4646C23.0661 12.8932 25.1325 12.848 27.1012 13.3337C29.07 13.8194 30.8677 14.8179 32.3037 16.2232C33.8623 17.7443 34.9257 19.6834 35.3595 21.7957H36.7328V27.3076H36.6586L32.2542 31.2689L25.6972 30.542V28.519H31.6728L32.6749 27.3439ZM20.9835 23.7218C21.3547 23.7218 21.7135 23.8672 21.9733 24.1337C22.2344 24.3915 22.3809 24.7398 22.3809 25.1028C22.3809 25.4659 22.2344 25.8142 21.9733 26.072C21.7135 26.3264 21.3547 26.4717 20.9835 26.4717C20.2041 26.4717 19.5732 25.866 19.5732 25.1028C19.5732 24.3397 20.2041 23.7218 20.9835 23.7218ZM27.7262 23.7218C28.5056 23.7218 29.1242 24.3397 29.1242 25.1028C29.1242 25.866 28.5056 26.4717 27.7262 26.4717C26.9467 26.4717 26.3158 25.866 26.3158 25.1028C26.3158 24.7366 26.4644 24.3853 26.7289 24.1263C26.9934 23.8673 27.3521 23.7218 27.7262 23.7218Z" fill="white"/>
	</svg>
</template>

<style scoped lang="scss">

</style>